import React, { useState } from 'react';
import { Link } from "gatsby"

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    sectionTitle: {
        color: theme.palette.text.secondary,
        fontWeight: 500
    },
    selectedLesson: {
        fontWeight: 500
    },
    listItem: {
        paddingRight: "10px",
        paddingTop: "3px",
        paddingBottom: "3px"
    },
    lessonTitle: {
        fontSize: "90%"
    }
}));

const lessonRenderList = (
    pageInfo, 
    courseInfo,
    lessonList,
    classes, 
    expandedSection, 
    handleSetExpandedSection, 
    handleMobileMenuOpen
    ) => {
    
    const sectionList = courseInfo.sections;

    return (
        sectionList.map(section => 
            {
                lessonList.filter(lesson => lesson.sectionId === section.sectionId)
                return (
                    <div key={section.sectionId}>

                        <ListItem 
                            button
                            onClick={() => handleSetExpandedSection(section.sectionId)}
                            classes={{ root: classes.listItem }}
                        >
                            <ListItemText primary={section.sectionTitle} classes={{primary: classes.sectionTitle}} />
                            {expandedSection[section.sectionId] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Collapse in={expandedSection[section.sectionId]} timeout="auto" unmountOnExit>
                        {
                        lessonList.filter((lesson) => lesson.sectionId === section.sectionId)
                            .map(lesson => {
                                let selectedLesson = null;
                                if (lesson.lessonId === pageInfo.lessonId) {
                                    selectedLesson = true;
                                } else {
                                    selectedLesson = false;
                                }
                                return (
                                    <ListItem 
                                        button 
                                        key={lesson.lessonId} 
                                        component={Link} 
                                        onClick={handleMobileMenuOpen} 
                                        to={`/${lesson.slug}`}
                                        classes={{root: classes.listItem}}
                                    >
                                        <ListItemText
                                            primary={lesson.lessonTitle}
                                            className={classes.nested} 
                                            classes={
                                                {
                                                    primary: clsx({[classes.lessonTitle]: true, [classes.selectedLesson]: selectedLesson})
                                                }
                                            }
                                        />
                                    </ListItem>
                                );
                            })
                        }
                        </Collapse>
                    </div>
                );
            }
        )
    );
};

const LessonList = ({ props }) => {

    const classes = useStyles();

    const initExpendedSection = props.courseInfo.sections.reduce((acc, cur) => {
        if (cur.sectionId === props.pageInfo.sectionId) {
            return {...acc, [cur.sectionId]: true}
        } else {
            return {...acc, [cur.sectionId]: false}
        }
    }, {});

    // if (props.pageInfo.type === "course") {
    //     initExpendedSection[1] = true;
    // }

    const [expandedSection, setExpandedSection] = useState(initExpendedSection);
    
    const handleSetExpandedSection = (sectionId) => {
        setExpandedSection(expandedSection => {
            return {...expandedSection, [sectionId]: !expandedSection[sectionId]}
        });
    }

    return (
        <div>
            {lessonRenderList(
                props.pageInfo, 
                props.courseInfo, 
                props.lessonList, 
                classes, 
                expandedSection, 
                handleSetExpandedSection, 
                props.handleMobileMenuOpen
            )}
        </div>
    );
};

export default LessonList;