import React from "react"

const NewSiteNotice = () => {
  return (
    <div
      style={{
        marginLeft: "-20px",
        marginRight: "-20px",
        marginTop: "20px",
        marginBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "14px",
        paddingBottom: "14px",
        border: "1px solid rgba(0, 0, 0, 0.06)",
        borderRadius: "4px",
        // borderLeft: "5px solid rgba(0, 0, 0, 0.06)",
        background: "rgba(0, 0, 0, 0.04)",
        textAlign: "center",
        fontWeight: "bold",
      }}
    >
      🔔 Check out{" "}
      <a
        rel="noopener noreferrer"
        href="https://opencantonese.org/"
        target="_blank"
      >
        our new website
      </a>{" "}
      for more Cantonese lessons.
    </div>
  )
}

export default NewSiteNotice
