import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';


const useStyles = makeStyles(theme => ({
    courseName: {
        fontWeight: 500
    },
    footer: {
        display: 'flex',
        justifyContent: "center",
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    }
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            <Typography variant="caption" align="center">
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div>Made with&nbsp;</div> <FavoriteIcon fontSize="small" /> <div>&nbsp;in Hong Kong.</div>
                </div>
                <div>© 2020 Open Cantonese. All Rights Reserved.</div>
            </Typography>
        </div>
    );
};

export default Footer;