// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import YouTubeIcon from "@material-ui/icons/YouTube"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import EmailIcon from "@material-ui/icons/Email"

import logo from "../images/logo.png"

const useStyles = makeStyles(theme => ({
  appBar: {
    color: theme.palette.text.primary,
    background: "#fff",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  button: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    width: "26px",
    marginRight: theme.spacing(1.2),
  },
  siteLogoLink: {
    lineHeight: "0.55",
  },
  siteNameGrow: {
    flexGrow: 1,
  },
  siteName: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontWeight: 500,
    flexGrow: 1,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconLink: {
    borderBottom: "none !important",
    marginLeft: "10px",
    marginTop: "6px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

const Header = props => {
  const classes = useStyles()

  return (
    <div>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleMobileMenuClose}
            className={classes.button}
          >
            <MenuIcon />
          </IconButton>

          <Link id="siteLogo" to={`/`} className={classes.siteLogoLink}>
            <img
              className={classes.logo}
              src={logo}
              alt="Open Cantonese Logo"
            />
          </Link>
          <div className={classes.siteNameGrow}>
            <Typography
              id="siteName"
              component={Link}
              to={`/`}
              noWrap
              className={classes.siteName}
            >
              Open Cantonese
            </Typography>
          </div>

          <a
            className={classes.iconLink}
            rel="noopener noreferrer"
            href="https://www.youtube.com/@opencant/"
            target="_blank"
          >
            <YouTubeIcon className={classes.icon} />
          </a>
          <a
            className={classes.iconLink}
            rel="noopener noreferrer"
            href="https://www.instagram.com/opencant/"
            target="_blank"
          >
            <InstagramIcon className={classes.icon} />
          </a>
          <a
            className={classes.iconLink}
            rel="noopener noreferrer"
            href="https://www.facebook.com/opencant/"
            target="_blank"
          >
            <FacebookIcon className={classes.icon} />
          </a>
          <a
            className={classes.iconLink}
            rel="noopener noreferrer"
            href="https://forms.gle/b2HV4aNE6gyKCqWY9"
            target="_blank"
          >
            <EmailIcon className={classes.icon} />
          </a>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
