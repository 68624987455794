import React from 'react';
import { Link } from "gatsby"

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ListItemText from '@material-ui/core/ListItemText';

import LessonList from './lessonList'

const useStyles = makeStyles(theme => ({
    courseName: {
        fontWeight: 500
    },
    listItem: {
        paddingRight: "10px",
        paddingTop: "3px",
        paddingBottom: "3px"
    }
}));

const MenuLesson = ({ props }) => {

    const classes = useStyles();

    return (
        <div>
            <List dense component="nav" aria-label="Menu">
                <ListItem button component={Link} to="/" classes={{ root: classes.listItem }}>
                    <ListItemText primary="All Courses"/>
                    <NavigateBeforeIcon />
                </ListItem>

                <ListItem button component={Link} to={`/${props.courseInfo.slug}`} classes={{ root: classes.listItem }}>
                    <ListItemText primary={props.courseInfo.courseShortTitle} classes={{primary: classes.courseName}}/>
                </ListItem>

                <LessonList props={props} />
            </List>
        </div>
    );
};

export default MenuLesson;