import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';

import ContentLesson from './contentLesson';
import ContentCourse from './contentCourse';

// const useStyles = makeStyles(theme => ({

// }));

const Content = ({ props }) => {
    // const classes = useStyles();

    if (props.pageInfo.type === "lesson" ) {
        return (
            <div>
                <ContentLesson props={props} />
            </div>
        );
    } else if (props.pageInfo.type === "course") {
        return (
            <div>
                <ContentCourse props={props} />
            </div>
        );
    } else {
        return (
            <div>
                {props.children}
            </div>
        )
    }
};

export default Content;