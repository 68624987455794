import React from "react"
import { Link } from "gatsby"

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
    courseName: {
        fontWeight: 500
    },
    listItem: {
        paddingRight: "10px",
        paddingTop: "3px",
        paddingBottom: "3px"
    }
}));

const courseListRender = (props, classes) => {
    return props.courseList.map(course => {
        return (
            <ListItem button key={course.courseId} component={Link} to={`/${course.slug}`} classes={{ root: classes.listItem }}>
                <ListItemText primary={course.courseShortTitle}/>
            </ListItem>
        );
    });
}

const Menu = ({props}) => {

    console.log(props)

    const classes = useStyles();

    return (
        <div>
            <List dense component="nav" aria-label="menu">

                <ListItem button component={Link} to="/" classes={{ root: classes.listItem }}>
                    <ListItemText primary="All Courses" />
                </ListItem>

                {courseListRender(props, classes)}

            </List> 
        </div>
    );
}

export default Menu;