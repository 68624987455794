import React from "react"

import MenuLesson from "./menuLesson"
import MenuPage from "./menuPage"

const Menu = (props) => {
    if (props.pageInfo.type === 'lesson' || props.pageInfo.type === 'course') {
        return (
            <div>
                <MenuLesson props={props} />
            </div>
        );
    } else {
        return (
            <div>
               <MenuPage props={props} />
            </div>
        );
    }
};

export default Menu;