import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { makeStyles } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import Typography from "@material-ui/core/Typography"

import LessonListContent from "./lessonListContent"
import Footer from "./footer"
import NewSiteNotice from "./newSiteNotice"

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(1.5),
  },
  lessonTitle: {
    paddingTop: theme.spacing(2.1),
    paddingBottom: theme.spacing(0.1),
  },
  divider: {
    paddingTop: theme.spacing(3),
  },
  footer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  lessonList: {
    paddingTop: theme.spacing(0.5),
  },
}))

const CourseTitleRender = props => {
  return <h1>{props.courseInfo.courseTitle}</h1>
}

const breadcrumbsRender = props => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="inherit" />}
      aria-label="breadcrumb"
    >
      <Typography variant="caption" color="inherit">
        {props.courseInfo.courseTitle}
      </Typography>
      <Typography variant="caption" color="inherit">
        Course Introduction
      </Typography>
    </Breadcrumbs>
  )
}

const ContentCourse = ({ props }) => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <NewSiteNotice />
      {breadcrumbsRender(props)}
      <div className={classes.lessonTitle}>{CourseTitleRender(props)}</div>

      <h2>Course Introduction</h2>
      <MDXProvider>
        <MDXRenderer>{props.courseInfo.body}</MDXRenderer>
      </MDXProvider>

      <div className={classes.lessonList}>
        <h2>List of Lessons</h2>
        <List dense aria-label="lesson list">
          <LessonListContent props={props} />
        </List>
      </div>

      <div className={classes.divider}>
        <Divider light />
      </div>
      <Footer />
    </div>
  )
}

export default ContentCourse
