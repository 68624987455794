/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
import clsx from 'clsx'
import { Helmet } from 'react-helmet'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';

import Header from "./header"
import Menu from './menu'
import Content from './content'
// import "./layout.css"

const theme = createMuiTheme({
  typography: {
    fontSize: 15
  },
  palette: {
      background: {
          default: "#fff"
      }
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        padding: '8px 10px 8px 10px'
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0 auto",
    boxSizing: "border-box",
    display: "block",
    position: "static",
    '& p': {
    },
    '& li': {
    },
    '& a:not(.MuiButtonBase-root):not(#siteName):not(#siteLogo)': {
        textDecoration: "none",
        borderBottom: "1.6px rgb(232,232,232) solid",
        color: theme.palette.text.secondary
    },
    '& ruby': {
      fontFamily: "Noto Sans TC",
      fontSize: 13,
      color: theme.palette.text.secondary
    },
    '& rt': {
      fontFamily: theme.typography.fontFamily,
      paddingLeft: "2px",
      paddingRight: "2px",
      fontSize: 13,
      color: theme.palette.text.secondary
    }
    },
  header: {
    position: "fixed",
    zIndex: 20
  },
  main: {
    boxSizing: "inherit",
    display: "block",
    position: "static",
    paddingTop: "48px"
    },
  content: {
    zIndex: "auto",
    paddingLeft: "20vw",
    margin: "auto",
    width: "75%",
    Background: "red",
    [theme.breakpoints.only('md')]:{
      paddingLeft: "25vw",
      width: "85%"
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      width: "85%"
    }
  },
  innerContent: {
    position: "relative"
  },
  menu: {
    border: "0px none",
    boxSizing: "inherit",
    display: "block",
    position: "fixed",
    pointerEvents: "auto",
    bottom: "0rem",
    // transform: "translateX(0px)",
    // transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s",
    top: "48px",
    width: "20vw",
    height: "calc(100vh - 48px)",
    [theme.breakpoints.up('md')]:{
      opacity: 1
    },
    [theme.breakpoints.only('md')]:{
      width: "25vw"
    },
    [theme.breakpoints.only('sm')]:{
      width: "45vw"
    },
    [theme.breakpoints.only('xs')]:{
      width: "70vw"
    },
    [theme.breakpoints.down('sm')]:{
      boxShadow: "rgba(51, 51, 51, 0.08) 0px 4px 16px, rgba(79, 79, 79, 0.16) 0px 8px 24px",
      height: "100vh",
      top: "0px"
    },
  },
  menuMobileClose: {
    [theme.breakpoints.down('sm')]:{
      display: "none"
    }
  },
  menuMobileOpen: {
    [theme.breakpoints.down('sm')]:{
      zIndex: 30
    }
  },
  nav: {
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
    background: "white",
    height: "100%",
    overflowY: "auto"
  },
  fab: {
    position: "fixed",
    top: theme.spacing(2),
    zIndex: 30,
    // transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s",
    [theme.breakpoints.up('md')]:{
      display: "none"
    },
    [theme.breakpoints.only('sm')]:{
      left: "calc(45vw + 15px)"
    },
    [theme.breakpoints.only('xs')]:{
      left: "calc(70vw + 15px)"
    }
  }
}));

const Layout = (props) => {
  const classes = useStyles();
  
  const [mobileMenuClose, setMobileMenuClose] = React.useState(true);
  const handleMobileMenuClose = () => {
      setMobileMenuClose(!mobileMenuClose);
    };

  return (
    <> 
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Noto+Sans+TC:400&display=swap" rel="stylesheet" />       
      </Helmet>
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <div className={classes.root}>
          <header className={classes.header}>
            <Header mobileMenuClose={mobileMenuClose} handleMobileMenuClose={handleMobileMenuClose}/>
          </header>   
          <main className={classes.main}>
            <div className={classes.content}>
              <div className={classes.innerContent}>
                <Content props={props} />
              </div>
            </div>
            <div className={clsx({[classes.menu]: true}, {[classes.menuMobileClose]: mobileMenuClose}, {[classes.menuMobileOpen]: !mobileMenuClose})}>
              <nav className={classes.nav}>
                <Menu 
                  pageInfo={props.pageInfo}
                  lessonInfo={props.lessonInfo}
                  courseInfo={props.courseInfo}
                  lessonList={props.lessonList}
                  courseList={props.courseList}
                />
              </nav>
            </div>
          </main>
          <Fab 
            color="default"
            className={clsx({[classes.fab]: true}, {[classes.menuMobileClose]: mobileMenuClose})}
            onClick={handleMobileMenuClose}
            aria-label="close menu"
          >
            <CloseIcon />
          </Fab>
        </div>
      </ThemeProvider>
    </>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout