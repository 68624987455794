import React from "react"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { makeStyles } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import Typography from "@material-ui/core/Typography"

import NewSiteNotice from "./newSiteNotice"

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(1.5),
  },
  lessonTitle: {
    paddingTop: theme.spacing(2.1),
    paddingBottom: theme.spacing(0.1),
  },
  divider: {
    paddingTop: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
}))

const lessonTitleRender = props => {
  return <h1>{props.lessonInfo.lessonTitle}</h1>
}

const breadcrumbsRender = props => {
  const sectionTitle = props.courseInfo.sections.find(
    section => section.sectionId === props.pageInfo.sectionId
  ).sectionTitle

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="inherit" />}
      aria-label="breadcrumb"
    >
      <Typography
        variant="caption"
        color="inherit"
        component={Link}
        to={`/${props.courseInfo.slug}`}
      >
        {props.courseInfo.courseTitle}
      </Typography>
      <Typography variant="caption" color="inherit">
        {sectionTitle}
      </Typography>
    </Breadcrumbs>
  )
}

const footerRender = (props, classes) => {
  // Find index of current lesson
  const lessonIndex = props.lessonList.findIndex(
    lesson => lesson.lessonId === props.pageInfo.lessonId
  )

  // Check if previous or next lesson exists
  let previousLesson, nextLesson
  if (lessonIndex >= 0 && lessonIndex < props.lessonList.length - 1) {
    nextLesson = props.lessonList[lessonIndex + 1]
  }
  if (lessonIndex > 0 && lessonIndex < props.lessonList.length) {
    previousLesson = props.lessonList[lessonIndex - 1]
  }

  const renderPreviousButton = previousLesson => {
    if (previousLesson) {
      return (
        <Button
          startIcon={<KeyboardArrowLeft />}
          component={Link}
          to={`/${previousLesson.slug}`}
          style={{ textTransform: "none", textDecoration: "none" }}
        >
          {previousLesson.lessonTitle}
        </Button>
      )
    } else {
      return (
        <Button
          startIcon={<KeyboardArrowLeft />}
          component={Link}
          to={`/${props.courseInfo.slug}`}
          style={{ textTransform: "none", textDecoration: "none" }}
        >
          Course Introduction
        </Button>
      )
    }
  }

  const renderNextButton = nextLesson => {
    if (nextLesson) {
      return (
        <Button
          endIcon={<KeyboardArrowRight />}
          component={Link}
          to={`/${nextLesson.slug}`}
          style={{ textTransform: "none", textDecoration: "none" }}
        >
          {nextLesson.lessonTitle}
        </Button>
      )
    } else {
      return (
        <Button
          disabled
          endIcon={<KeyboardArrowRight />}
          style={{ textTransform: "none", textDecoration: "none" }}
        >
          This is the last lesson.
        </Button>
      )
    }
  }
  return (
    <div className={classes.footer}>
      {renderPreviousButton(previousLesson)}
      {renderNextButton(nextLesson)}
    </div>
  )
}

const ContentLesson = ({ props }) => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <NewSiteNotice />
      {breadcrumbsRender(props)}
      <div className={classes.lessonTitle}>{lessonTitleRender(props)}</div>
      <MDXProvider>
        <MDXRenderer>{props.lessonInfo.body}</MDXRenderer>
      </MDXProvider>

      <div className={classes.divider}>
        <Divider />
      </div>
      {footerRender(props, classes)}
    </div>
  )
}

export default ContentLesson
